import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PersonIcon from '@mui/icons-material/Person';
import { Skeleton } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import { StreamResponse } from 'domain/api';
import { t } from 'i18next';
import { generateConversationTitle } from 'model/services/conversations/fetchConversations';
import { fetchSelectedConversation } from 'model/services/conversations/fetchSelectedConversation';
import { ConversationHistoryItem, IMessage, SourceFileInfo, StreamChunk } from 'model/services/promt/types';
import { conversationsActions } from 'model/slices/conversationsSlice';
import { modelSelectionActions } from 'model/slices/modelSelectionSlice';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TStore } from 'store';

import CustomSnackbar from './CustomSnackbar';
import ErrorMessage from './ErrorMessage';
import OutputSourceList from './OutputSourceList';
import SanitizedMessageComponent from './SanitizedMessageComponent';

interface ChatWindowProps {
  typing: boolean;
  setTyping: (flag: boolean) => void;
  setIsPromptResponseLoaded: (flag: boolean) => void;
  rawPromptResponse: StreamResponse | undefined;
  userID: string;
}

const message_copy_content = (message: IMessage) => {
  let sources_string = '';
  const sources_i18n = t('Output Sources');
  const pagenumber_i18n = t('Page Numbers');
  if (message.source) {
    sources_string = message.source
      .map(
        (s, idx) =>
          `${idx + 1}. ${s.fileName}: ${pagenumber_i18n} ${s.fileDetails
            .map(fileDetail => fileDetail.page)
            .join(', ')}`,
      )
      .join('\n');
  }
  return message.text + `\n\n${sources_i18n}:\n` + sources_string;
};

export const ChatWindow: React.FC<ChatWindowProps> = ({
  typing,
  setTyping,
  setIsPromptResponseLoaded,
  rawPromptResponse,
  userID,
}) => {
  const [copySnackbarOpen, setCopySnackbarOpen] = useState<boolean>(true);
  const [sources, setSources] = useState<SourceFileInfo[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [promptErrorMessage, setPromptErrorMessage] = useState('');
  const [chunkStatus, setChunkStatus] = useState('');
  const dispatch = useDispatch();
  const {
    currentConversationChat: chatMessages,
    isPastConversation,
    promptResponse,
    displayedPromptResponse,
    activeConversation,
    conversationLoading,
    conversationLoadingError,
  } = useSelector((state: TStore) => state.conversationsReducer);
  const isStopSignalOn = useSelector((state: TStore) => state.conversationsReducer.stopConversationSignal);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const typingIntervalRef = React.useRef<undefined | NodeJS.Timer>(undefined);
  const isStopSignalOnRef = useRef(isStopSignalOn);

  useEffect(() => {
    isStopSignalOnRef.current = isStopSignalOn;
  }, [isStopSignalOn]);

  useEffect(() => {
    dispatch(modelSelectionActions.modelSelectionDisable());
  }, []);

  useEffect(() => {
    if (!promptResponse || !chatMessages.length) {
      setIsPromptResponseLoaded(false);
      return;
    }
    const botResponse: IMessage = {
      id: chatMessages[chatMessages.length - 1].id + 1,
      text: promptResponse,
      isUserMessage: false,
      source: sources,
    };

    // Code to type the bot response letter by letter after the typing indicator is hidden
    let typedText = displayedPromptResponse;
    // Clear existing interval if it exists
    if (typingIntervalRef.current) {
      clearInterval(typingIntervalRef.current);
    }
    const typingInterval = 30;
    const typingIntervalId = setInterval(() => {
      const leftoverTextBufferLength = botResponse.text.length - typedText.length;
      if (isStopSignalOn) {
        clearInterval(typingIntervalId);
        setIsPromptResponseLoaded(false);
        return;
      }
      if (leftoverTextBufferLength > 0) {
        const textStepSize = Math.min(5, Math.ceil(leftoverTextBufferLength / 10));
        typedText = document.hidden ? botResponse.text : botResponse.text.substring(0, typedText.length + textStepSize);
        if (typedText.length > 0) {
          setTyping(false);
        }
        dispatch(conversationsActions.setDisplayedPromptResponse(typedText));
        if (chatMessages[chatMessages.length - 1].isUserMessage) {
          dispatch(
            conversationsActions.setCurrentConversationChat([
              ...chatMessages,
              {
                id: botResponse.id,
                text: typedText,
                isUserMessage: false,
                source: chunkStatus === 'FINISHED' ? botResponse.source : null,
              },
            ]),
          );
        } else {
          dispatch(
            conversationsActions.setCurrentConversationChat([
              ...chatMessages.slice(0, chatMessages.length - 1),
              {
                id: botResponse.id + 1,
                text: typedText,
                isUserMessage: false,
                source: chunkStatus === 'FINISHED' ? botResponse.source : null,
                isSourceAvailable: chunkStatus === 'FINISHED' ? true : false,
              },
            ]),
          );
        }
      } else {
        clearInterval(typingIntervalId);
      }
    }, typingInterval);
    // Save the interval reference to clear it later
    typingIntervalRef.current = typingIntervalId;

    // Clean up the interval when the component unmounts or when data changes
    return () => {
      clearInterval(typingIntervalRef.current);
    };
  }, [promptResponse, sources, isStopSignalOn, chunkStatus]);

  useEffect(() => {
    if (chatMessages?.length) {
      containerRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [chatMessages]);

  useEffect(() => {
    if (rawPromptResponse) processStream(rawPromptResponse);
  }, [rawPromptResponse]);

  const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
  const fetchAndSetSources = async () => {
    for (let attempt = 0; attempt < 5; attempt++) {
      console.log('attempt', attempt);
      const result = (await fetchSelectedConversation(
        dispatch,
        userID,
        activeConversation?.sessionId,
      )) as ConversationHistoryItem[];

      console.log(chatMessages.length, result.length, result.length < chatMessages.length / 2);
      if (result.length < chatMessages.length / 2) {
        await delay(2000);
        continue;
      }

      if (result.length > 0) {
        const currSource = result[result.length - 1].sources;
        setSources(currSource);
      } else {
        await delay(2000);
        continue;
      }
      return result;
    }
    return undefined;
  };

  // Function to get and process chunks data coming in prompt response
  const processStream = async (stream: StreamResponse) => {
    const reader = stream.body?.getReader();

    if (reader) {
      try {
        // eslint-disable-next-line no-constant-condition
        while (true) {
          const { done, value } = await reader.read();
          setIsPromptResponseLoaded(true);

          if (isStopSignalOnRef.current) {
            setIsPromptResponseLoaded(false);
            break;
          }
          // check if last chunk received
          if (done) {
            // API call to fetch correct sources details to display in current chat
            console.log('Calling set srouces');
            const conversationHistory = await fetchAndSetSources();

            // API call to generate conversation title - called for new conversation for 1st prompt only
            if (
              !isPastConversation &&
              chatMessages.length < 2 &&
              activeConversation?.sessionId &&
              activeConversation.sessionTitle.includes('session')
            ) {
              const sessionDetails = {
                sessionId: activeConversation?.sessionId,
                userId: userID,
              };
              const generatedChatTitle = await generateConversationTitle(dispatch, sessionDetails);
              if (generatedChatTitle) {
                dispatch(
                  conversationsActions.updateConversationTitle({
                    ...sessionDetails,
                    sessionTitle: generatedChatTitle,
                  }),
                );
                dispatch(
                  conversationsActions.setActiveConversation({
                    ...sessionDetails,
                    sessionTitle: generatedChatTitle,
                    updatedAt: '',
                  }),
                );
              } else {
                setSnackbarOpen(true);
                setSnackbarMessage('magenta_gateway/session/title/generate');
              }
            }
            setIsPromptResponseLoaded(false);
            if (conversationHistory) {
              const actualText = conversationHistory[conversationHistory.length - 1].response;
              dispatch(conversationsActions.overwritePromptResponse(actualText));
            }
            break;
          }
          const chunkText = new TextDecoder().decode(value);

          for (const row of chunkText.split('\n')) {
            if (!row) {
              continue;
            }
            try {
              const parsedElement = JSON.parse(row) as StreamChunk;
              dispatch(conversationsActions.setPromptResponse(parsedElement.chunk));
              setChunkStatus(parsedElement.status);
              if (parsedElement.chunk === undefined) {
                setPromptErrorMessage(JSON.stringify(parsedElement));
                setSnackbarOpen(true);
                setSnackbarMessage('magenta_gateway/chat/stream');
              }
            } catch (error) {
              console.log('error in streaming: ', error);

              await fetchAndSetSources();

              const promptResponseFromChunk = row.substring(0, row.indexOf(', "status": "FINISHED"')) + '}';

              try {
                const parsedElement = JSON.parse(promptResponseFromChunk);
                dispatch(conversationsActions.setPromptResponse(parsedElement.chunk));
              } catch (parseError) {
                console.log('error parsing prompt response: ', parseError);
              }

              setIsPromptResponseLoaded(false);
            }
          }
          if (done) {
            break;
          }
        }
        reader.releaseLock();
      } catch (error) {
        console.log('error occured: ', error);
        setIsPromptResponseLoaded(false);
      }
    }
  };

  // Function to handle when user clicks on copy icon
  const handleCopyClick = (copiedText: string) => {
    setCopySnackbarOpen(true);
    navigator.clipboard.writeText(copiedText);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const shownUserName = userID.split('@')[0].replace(/[^a-zA-ZöüäÖÜÄ0-9ß-]/g, ' ');

  if (shownUserName == 'not a name') {
    console.log(copySnackbarOpen);
  }

  return (
    <>
      <div className="mt-24 py-0" id="chatWindowMainContainer">
        {/* section to display chat messages */}
        <div id="conversationsContainer" className="flex flex-col gap-6 pb-8">
          {chatMessages &&
            !conversationLoading &&
            chatMessages.map((message, index) => (
              <div key={`${message.id}_${index}`} className={`flex flex-row items-start`} id="chatMessage">
                {/* User & Bot messages chat section */}
                <div className="flex flex-grow gap-4 rounded bg-[#FBFBFB] px-4 py-3 sm:flex-row">
                  {/* user & bot icons */}
                  <div className="pt-[6px]">
                    {
                      <Avatar alt="Avatar" variant="circular">
                        {message.isUserMessage ? (
                          <PersonIcon />
                        ) : (
                          <img src="./assets/Logo.svg" alt="logo" className="bg-[#FBFBFB]" />
                        )}
                      </Avatar>
                    }
                  </div>

                  {/* user & bot messages */}
                  <div id="promptMessageParentContainer" className="py-2">
                    <div className="font-semibold capitalize">
                      {message.isUserMessage ? shownUserName : 'Smart Health Chat'}
                    </div>
                    <div
                      className="flex flex-col whitespace-pre-wrap px-0 py-1 text-[14px]"
                      id={message.isUserMessage ? 'promptQuestionId' : 'promptAnswerId'}
                    >
                      <SanitizedMessageComponent message={message.text.trim()} errMsg={promptErrorMessage} />
                    </div>

                    {/* Section to display sources of the responses - start */}
                    {!message?.isUserMessage && message?.source?.length !== 0 && message?.isSourceAvailable && (
                      <div>
                        <OutputSourceList sources={message.source} />
                      </div>
                    )}

                    {/* Copy content icon */}
                    {!message?.isUserMessage && (
                      <div className="py-2">
                        {message.text !== 'undefined' && (
                          <IconButton
                            id="contentCopyIconBtn"
                            onClick={() => handleCopyClick(message_copy_content(message))}
                            size="small"
                          >
                            <ContentCopyIcon fontSize="small" className="cursor-pointer" />
                          </IconButton>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}

          {/* Skelaton part until we have no response */}
          {conversationLoading && (
            <>
              {[...Array(3)].map((_, index) => (
                <Skeleton
                  key={`skeleton-${index}`}
                  variant="rectangular"
                  className={`py-4 sm:py-6 ${index % 2 === 1 ? 'bg-[#FBFBFB]' : ''}`}
                  width="100%"
                >
                  <Avatar className="scale-90" alt="Bot" variant="rounded" src="./assets/systems_Icon.png" />
                  <span>.</span>
                </Skeleton>
              ))}
            </>
          )}

          {/* Show typing indicator if 'typing' is true */}
          {typing && (
            <div className="flex flex-row items-start gap-4 rounded bg-[#FBFBFB] px-4 py-3">
              <img className="w-10 pt-[6px]" src="./assets/0763238990792b1c642294884abbf265.gif" alt="Bot" />
              <div className="flex flex-col gap-1">
                <span className="font-semibold">Smart Health Chat</span>
                <span className="text-[14px]">{t('The AI is calculating a response...')}</span>
              </div>
            </div>
          )}
        </div>

        <div ref={containerRef} />

        {/* Section to show error message if there is any error in response */}
        {(!chatMessages && !conversationLoading) ||
          (conversationLoadingError && (
            <ErrorMessage errorMessage={t('Loading the content failed because of an unknown error')} />
          ))}

        {/* Snackbar to show copy confirmation */}
        <Snackbar
          open={true}
          autoHideDuration={1500}
          onClose={() => setCopySnackbarOpen(false)}
          message={`${t('Message copied')}`}
        />
      </div>
      <CustomSnackbar open={snackbarOpen} message={snackbarMessage} onClose={handleSnackbarClose} />
    </>
  );
};
